/*
 *
 * LoginPage constants
 *
 */

export const LOGOUT_REQUEST = 'app/App/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/App/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/App/LOGOUT_ERROR';

export const COLLAPSED_SIDEBAR = 'app/App/COLLAPSED_SIDEBAR';
export const COLLAPSED_DRAWER = 'app/App/COLLAPSED_DRAWER';

export const GET_CURRENCIES_REQUEST = 'app/App/GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'app/App/GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_ERROR = 'app/SettingsPage/GET_CURRENCIES_ERROR';

export const SELECT_CURRENCY = 'app/App/SELECT_CURRENCY';

export const NEXT_STEP = 'app/App/NEXT_STEP';
export const PREVIOUS_STEP = 'app/App/PREVIOUS_STEP';

export const CHANGE_INPUT_NUMBER = 'app/App/CHANGE_INPUT_NUMBER';
export const CHANGE_INPUT = 'app/App/CHANGE_INPUT';

export const TOGGLE_MODAL = 'app/App/TOGGLE_MODAL';

export const CHECK_EMAIL_INVALID = 'app/App/CHECK_EMAIL_INVALID';
export const CHECK_EMAIL_REQUEST = 'app/App/CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'app/App/CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_ERROR = 'app/App/CHECK_EMAIL_ERROR';

export const GET_MESSAGES_REQUEST = 'app/App/GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'app/App/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'app/App/GET_MESSAGES_ERROR';

export const OPEN_MESSAGE_MODAL = 'app/App/OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'app/App/CLOSE_MESSAGE_MODAL';

export const TOGGLE_CONFIRM_MODAL = 'app/App/TOGGLE_CONFIRM_MODAL';

export const READ_MESSAGE_REQUEST = 'app/App/READ_MESSAGE_REQUEST';
export const READ_MESSAGE_SUCCESS = 'app/App/READ_MESSAGE_SUCCESS';
export const READ_MESSAGE_ERROR = 'app/App/READ_MESSAGE_ERROR';

export const READ_ALL_MESSAGES_REQUEST = 'app/App/READ_ALL_MESSAGES_REQUEST';
export const READ_ALL_MESSAGES_SUCCESS = 'app/App/READ_ALL_MESSAGES_SUCCESS';
export const READ_ALL_MESSAGES_ERROR = 'app/App/READ_ALL_MESSAGES_ERROR';

export const GET_NOTIFICATIONS_REQUEST = 'app/App/GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'app/App/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'app/App/GET_NOTIFICATIONS_ERROR';
